import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';
import { useDispatch } from 'react-redux';
import { addCreationSuccessToast, addErrorToast, addToast } from '@store/store/slices/toast.slice';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import { getCategories, createArticle } from '../../services';
import { type Category } from '../../services/types';
import styles from '../styles/styles.module.scss';

type FormData = {
    category: Category | null;
    title: string;
    subtitle?: string;
    titleDate?: Date;
    priority?: number;
    image?: FileList;
};

export const CreateArticle = () => {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<FormData>();
    const navigate = useNavigate();
    const { referenceId } = useParams<{ referenceId: string }>();
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isAdminOrMarket, rootAdminAndMarket } = usePermissions();

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getCategories();

                setCategories(response.data.categories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const onSubmit = async (data: FormData) => {
        setIsSubmitting(true);
        const formData = new window.FormData();

        if (referenceId) {
            formData.append('referenceId', referenceId);
            formData.append('title', data.title);

            if (data.titleDate) formData.append('titleDate', data.titleDate.toISOString());

            if (data.priority !== undefined) formData.append('priority', data.priority.toString());
        } else {
            formData.append('title', data.title);

            if (data.category?.id) formData.append('categoryId', data.category?.id?.toString() || '');

            if (data.subtitle) formData.append('subtitle', data.subtitle);

            if (data.titleDate) formData.append('titleDate', data.titleDate.toISOString());

            if (selectedFile) formData.append('image', selectedFile);
        }

        try {
            const { data } = await createArticle(formData);

            dispatch(addCreationSuccessToast());

            reset();
            setSelectedFile(null);

            if (referenceId) {
                if (data?.data?.id) navigate(`/references/${referenceId}/articles/${data?.data?.id}`);
            } else {
                if (data?.data?.id) navigate(`/articles/${data?.data?.id}`);
            }
        } catch (error) {
            console.error('Error creating article:', error);
            dispatch(addErrorToast((error as { message: string }).message));
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFileUpload = (event: { files: File[] }) => {
        const file = event.files[0];
        setSelectedFile(file);
    };

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={styles.formWrapper}>
                    <span className={classNames(styles.editTitle)}>
                        <BackArrow link={referenceId ? '/references' : '/articles'} />
                        Создать статью
                    </span>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        {!referenceId && (
                            <div className={styles.row}>
                                <Controller
                                    name="category"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <div className="flex flex-column gap-2 flex-1">
                                            <label htmlFor="category">Категория</label>
                                            <Dropdown
                                                {...field}
                                                options={categories}
                                                optionLabel="name"
                                                placeholder="Выберите категорию"
                                                className={errors.category ? 'p-invalid' : ''}
                                                disabled={!isAdminOrMarket}
                                            />
                                            {errors.category && (
                                                <small className="p-error">{errors.category.message}</small>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        )}

                        <div className={styles.row}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Заголовок обязателен',
                                    maxLength: { value: 120, message: 'Максимальная длина 120 символов' },
                                }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="title">Заголовок</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите заголовок"
                                            className={errors.title ? 'p-invalid' : ''}
                                            disabled={!isAdminOrMarket}
                                        />
                                        {errors.title && <small className="p-error">{errors.title.message}</small>}
                                    </div>
                                )}
                            />
                        </div>

                        {referenceId && (
                            <div className={styles.row}>
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex flex-column gap-2 flex-1">
                                            <label htmlFor="priority">Приоритет</label>
                                            <InputNumber
                                                {...field}
                                                placeholder="Введите приоритет"
                                                useGrouping={false}
                                                className={errors.priority ? 'p-invalid' : ''}
                                                onChange={(e) => field.onChange(e.value)}
                                                value={field.value}
                                                disabled={!isAdminOrMarket}
                                            />
                                        </div>
                                    )}
                                />
                                {errors.priority && <small className="p-error">{errors.priority.message}</small>}
                            </div>
                        )}

                        {!referenceId && (
                            <div className={styles.row}>
                                <Controller
                                    name="subtitle"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: { value: 250, message: 'Максимальная длина 250 символов' } }}
                                    render={({ field }) => (
                                        <div className="flex flex-column gap-2 flex-1">
                                            <label htmlFor="subtitle">Подзаголовок</label>
                                            <InputText
                                                {...field}
                                                placeholder="Введите подзаголовок"
                                                className={errors.subtitle ? 'p-invalid' : ''}
                                                disabled={!isAdminOrMarket}
                                            />
                                        </div>
                                    )}
                                />
                                {errors.subtitle && <small className="p-error">{errors.subtitle.message}</small>}
                            </div>
                        )}

                        <div className={styles.row}>
                            <Controller
                                name="titleDate"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="titleDate">Отображаемая дата публикации</label>
                                        <Calendar
                                            {...field}
                                            placeholder="Выберите дату"
                                            dateFormat="dd.mm.yy"
                                            locale="ru"
                                            disabled={!isAdminOrMarket}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        {!referenceId && (
                            <div className={styles.row}>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="image">Изображение</label>
                                            <div className={styles.fileUpload}>
                                                <FileUpload
                                                    {...field}
                                                    mode={'advanced'}
                                                    name="image"
                                                    accept="image/*"
                                                    customUpload
                                                    uploadHandler={handleFileUpload}
                                                    auto
                                                    chooseLabel="Загрузить изображение"
                                                    disabled={!isAdminOrMarket}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        )}

                        <HasAccess permissions={rootAdminAndMarket}>
                            <Button
                                type="submit"
                                label="Создать"
                                severity="secondary"
                                outlined
                                disabled={!isValid || isSubmitting}
                            />
                        </HasAccess>
                    </form>
                </div>
            </div>
        </div>
    );
};
