import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { type Product } from '@libs/types';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';
import { useDispatch } from 'react-redux';
import { addErrorToast, addToast, addUpdateSuccessToast } from '@store/store/slices/toast.slice';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import { getProducts, getReference, updateReference } from '../../services';
import styles from '../styles/styles.module.scss';

type ReferenceData = {
    id: number;
    productGuid: string;
    title: string;
    priority?: number;
    status: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED';
};

export const ReferenceCard = () => {
    const { referenceId } = useParams<{ referenceId: string }>();
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<ReferenceData>();
    const navigate = useNavigate();
    const [products, setProducts] = useState<Product[]>([]);
    const { rootAdminAndMarket, isAdminOrMarket } = usePermissions();

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getProducts({ pageable: {} });
                setProducts(response.data.data.rows);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchReference = async () => {
            try {
                const response = await getReference(referenceId!);
                const referenceData = response.data;
                setValue('id', referenceData.id);
                setValue('productGuid', referenceData.productGuid);
                setValue('title', referenceData.title);
                setValue('priority', referenceData.priority);
                setValue('status', referenceData.status);
            } catch (error) {
                console.error('Error fetching reference:', error);
            }
        };

        fetchProducts();
        fetchReference();
    }, [referenceId, setValue]);

    const onSubmit = async ({ id, ...data }: ReferenceData) => {
        const payload = {
            ...data,
            productGuid: data.productGuid || null,
        };

        try {
            await updateReference(referenceId!, payload);

            dispatch(addUpdateSuccessToast());
        } catch (error) {
            console.error('Error updating reference:', error);
            dispatch(addErrorToast((error as { message: string }).message));
        }
    };

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={styles.formWrapper}>
                    <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                        <span className={classNames(styles.title)}>
                            <BackArrow link={'/references'} />
                            Карточка справки
                        </span>
                        <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                            <Button
                                type="button"
                                label="Статьи"
                                severity="secondary"
                                outlined
                                onClick={() => navigate('articles')}
                            />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <div className={styles.row}>
                            <Controller
                                name="id"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="id">ID</label>
                                        <InputText
                                            value={`${field.value}`}
                                            placeholder="id"
                                            readOnly
                                            disabled={!isAdminOrMarket}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="productGuid"
                                control={control}
                                // rules={{ required: 'Продукт обязателен' }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="productGuid">Продукт</label>
                                        <Dropdown
                                            {...field}
                                            options={products}
                                            optionLabel="name"
                                            optionValue={'productGuid'}
                                            showClear
                                            placeholder="Выберите продукт"
                                            className={errors.productGuid ? 'p-invalid' : ''}
                                            disabled={!isAdminOrMarket}
                                        />
                                    </div>
                                )}
                            />
                            {errors.productGuid && <small className="p-error">{errors.productGuid.message}</small>}
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Название обязательно',
                                    maxLength: { value: 120, message: 'Максимальная длина 120 символов' },
                                }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="title">Название</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите название"
                                            className={errors.title ? 'p-invalid' : ''}
                                            disabled={!isAdminOrMarket}
                                        />
                                    </div>
                                )}
                            />
                            {errors.title && <small className="p-error">{errors.title.message}</small>}
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="priority"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="priority">Приоритет</label>
                                        <InputNumber
                                            {...field}
                                            placeholder="Введите приоритет"
                                            useGrouping={false}
                                            className={errors.priority ? 'p-invalid' : ''}
                                            onChange={(e) => field.onChange(e.value)}
                                            value={field.value}
                                            disabled={!isAdminOrMarket}
                                        />
                                    </div>
                                )}
                            />
                            {errors.priority && <small className="p-error">{errors.priority.message}</small>}
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue="DRAFT"
                                rules={{ required: 'Статус обязателен' }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="status">Статус</label>
                                        <Dropdown
                                            {...field}
                                            options={[
                                                { label: 'Черновик', value: 'DRAFT' },
                                                { label: 'Опубликовано', value: 'PUBLISHED' },
                                                { label: 'Архив', value: 'ARCHIVED' },
                                            ]}
                                            placeholder="Выберите статус"
                                            className={errors.status ? 'p-invalid' : ''}
                                            disabled={!isAdminOrMarket}
                                        />
                                    </div>
                                )}
                            />
                            {errors.status && <small className="p-error">{errors.status.message}</small>}
                        </div>
                        <HasAccess permissions={rootAdminAndMarket}>
                            <Button
type="submit" label="Сохранить" severity="secondary"
outlined />
                        </HasAccess>
                    </form>
                </div>
            </div>
        </div>
    );
};
