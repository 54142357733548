import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { type Product } from '@libs/types';
import { useDispatch } from 'react-redux';
import { addCreationSuccessToast, addErrorToast, addToast } from '@store/store/slices/toast.slice';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import { getProducts, createReference } from '../../services';
import styles from '../styles/styles.module.scss';

type FormData = {
    productGuid: string | null;
    title: string;
    priority?: number;
};

export const CreateReference = () => {
    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
        reset,
    } = useForm<FormData>();
    const navigate = useNavigate();
    const [products, setProducts] = useState<Product[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getProducts({ pageable: {} });
                setProducts(response.data.data.rows);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const onSubmit = async (data: FormData) => {
        setIsSubmitting(true);
        const formData = new FormData();

        if (data.productGuid) formData.append('productGuid', data.productGuid);
        formData.append('title', data.title);

        if (data.priority) formData.append('priority', data.priority.toString());

        try {
            const res = await createReference(formData);

            dispatch(addCreationSuccessToast());

            reset();

            if (res?.data?.id) {
                navigate(`/references/${res?.data?.id}`);
            }
        } catch (error) {
            console.error('Error creating reference:', error);
            dispatch(addErrorToast((error as { message: string }).message));
        } finally {
            setIsSubmitting(false);
        }
    };

    const productOptions = products.map((p) => ({ label: p.name, value: p.productGuid }));

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={styles.formWrapper}>
                    <span className={classNames(styles.editTitle)}>
                        <BackArrow link={'/references'} />
                        Создать справку
                    </span>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <div className={styles.row}>
                            <Controller
                                name="productGuid"
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="productGuid">Продукт</label>
                                        <Dropdown
                                            {...field}
                                            options={productOptions}
                                            optionLabel="label"
                                            placeholder="Выберите продукт"
                                            className={errors.productGuid ? 'p-invalid' : ''}
                                        />
                                    </div>
                                )}
                            />
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Название обязательно',
                                    maxLength: { value: 120, message: 'Максимальная длина 120 символов' },
                                }}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="title">Название</label>
                                        <InputText
                                            {...field}
                                            placeholder="Введите название"
                                            className={errors.title ? 'p-invalid' : ''}
                                        />
                                    </div>
                                )}
                            />
                            {errors.title && <small className="p-error">{errors.title.message}</small>}
                        </div>

                        <div className={styles.row}>
                            <Controller
                                name="priority"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex flex-column gap-2 flex-1">
                                        <label htmlFor="priority">Приоритет</label>
                                        <InputNumber
                                            placeholder="Введите приоритет"
                                            useGrouping={false}
                                            className={errors.priority ? 'p-invalid' : ''}
                                            onChange={(e) => field.onChange(e.value)}
                                            value={field.value}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <Button
                            type="submit"
                            label="Создать"
                            severity="secondary"
                            disabled={!isValid || isSubmitting}
                            outlined
                            style={{ margin: '0' }}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
