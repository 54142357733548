import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from '@modules/ErrorPage';
import { TechnicPage } from '@modules/TechnicPage';
import { PersonalAccount } from '@modules/PersonalAccount';
import { Layout } from '@libs/components';
import { SessionExpiredPage } from '@modules/SessionExpiredPage';
import { AdminLayout } from '@libs/components/layout/AdminLayout';
import { MainPage } from '@modules/MainPage';
import { NotificationsList } from '@modules/AdminPage/Notifications/NotificationsList';
import { NotificationСreatePage } from '@modules/AdminPage/Notifications/NotificationCreatePage';
import { NotificationEditPage } from '@modules/AdminPage/Notifications/NotificationEditPage';
import { Articles } from '@modules/AdminPage/ContentManagement/Articles';
import { CreateArticle } from '@modules/AdminPage/ContentManagement/Articles/CreateArticle';
import { ArticleCard } from '@modules/AdminPage/ContentManagement/Articles/ArticleCard';
import { Editor } from '@modules/AdminPage/ContentManagement/Editor';
import { SubscriptionsList } from '@modules/AdminPage/Subscriptions/SubscriptionsList';
import { SubscriptionPage } from '@modules/AdminPage/Subscriptions/SubscriptionPage';
import { OrdersList } from '@modules/AdminPage/Orders/OrdersList';
import { References } from '@modules/AdminPage/ContentManagement/References';
import { CreateReference } from '@modules/AdminPage/ContentManagement/References/CreateReference';
import { ReferenceCard } from '@modules/AdminPage/ContentManagement/References/ReferenceCard';
import { OrderPage } from '@modules/AdminPage/Orders/OrderPage';
import { UsersList } from '@modules/AdminPage/Users/UsersList';
import { UserDetail } from '@modules/AdminPage/Users/UserDetail';
import { ProductsList } from '@modules/AdminPage/Products/ProductsList';
import { ProductPage } from '@modules/AdminPage/Products/ProductPage';
import { TariffPage } from '@modules/AdminPage/Products/TariffPage';
import { PrivateRoute } from '@libs/components/overlay/PrivateRoute';
import { DiscountsList } from '@modules/AdminPage/Discounts/DiscountsList';
import { DiscountPage } from '@modules/AdminPage/Discounts/DiscountPage';
import { RefundsList } from '@modules/AdminPage/Refunds/RefundsList';
import { RefundPage } from '@modules/AdminPage/Refunds/RefundPage';
import { ReportsList } from '@modules/AdminPage/Reports/ReportsList';
import { ReportCreatePage } from '@modules/AdminPage/Reports/ReportCreatePage';
import { ReportCard } from '@modules/AdminPage/Reports/ReportCard';
import { PermissionsList } from '@modules/AdminPage/Permissions/PermissionsList';
import { PermissionPage } from '@modules/AdminPage/Permissions/PermissionPage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <MainPage />,
            },
            {
                path: '/',
                element: <PrivateRoute component={<AdminLayout />} />,
                children: [
                    {
                        path: 'notifications',
                        element: <NotificationsList />,
                    },
                    {
                        path: 'notifications/create',
                        element: <NotificationСreatePage />,
                    },

                    {
                        path: 'notifications/:notificationId',
                        element: <NotificationEditPage />,
                    },
                    {
                        path: 'users',
                        element: <UsersList />,
                    },
                    {
                        path: 'users/:userId',
                        element: <UserDetail />,
                    },
                    {
                        path: 'articles',
                        element: <Articles />,
                    },
                    {
                        path: 'articles/create',
                        element: <CreateArticle />,
                    },
                    {
                        path: 'articles/:id',
                        element: <ArticleCard />,
                    },
                    {
                        path: 'articles/:id/edit',
                        element: <Editor />,
                    },
                    {
                        path: 'references',
                        element: <References />,
                    },
                    {
                        path: '/references/create',
                        element: <CreateReference />,
                    },
                    {
                        path: '/references/:referenceId',
                        element: <ReferenceCard />,
                    },
                    {
                        path: '/references/:referenceId/articles',
                        element: <Articles />,
                    },
                    {
                        path: '/references/:referenceId/articles/:id',
                        element: <ArticleCard />,
                    },
                    {
                        path: '/references/:referenceId/articles/create',
                        element: <CreateArticle />,
                    },
                    {
                        path: '/references/:referenceId/articles/:id/edit',
                        element: <Editor />,
                    },
                    {
                        path: 'subscriptions',
                        element: <SubscriptionsList />,
                    },
                    {
                        path: 'subscriptions/:subscriptionId',
                        element: <SubscriptionPage isEdit />,
                    },
                    {
                        path: 'subscriptions/create',
                        element: <SubscriptionPage isEdit={false} />,
                    },
                    {
                        path: 'orders',
                        element: <OrdersList />,
                    },
                    {
                        path: 'orders/:orderId',
                        element: <OrderPage />,
                    },
                    {
                        path: 'orders/:orderId/refunds',
                        element: <RefundsList />,
                    },
                    {
                        path: 'orders/:orderId/refunds/:refundId',
                        element: <RefundPage />,
                    },
                    {
                        path: 'products',
                        element: <ProductsList />,
                    },
                    {
                        path: 'products/:productId',
                        element: <ProductPage />,
                    },
                    {
                        path: 'tariffs/:productId/:tariffId',
                        element: <TariffPage />,
                    },
                    {
                        path: 'discounts/:tariffId/:tariffPlanId',
                        element: <DiscountsList />,
                    },
                    {
                        path: 'discounts/discount/:tariffId/:tariffPlanId/:discountId',
                        element: <DiscountPage isEdit />,
                    },
                    {
                        path: 'discounts/:tariffId/:tariffPlanId/create',
                        element: <DiscountPage isEdit={false} />,
                    },
                    {
                        path: 'reports',
                        element: <ReportsList />,
                    },
                    {
                        path: 'reports/create',
                        element: <ReportCreatePage />,
                    },
                    {
                        path: 'reports/:reportId',
                        element: <ReportCard />,
                    },
                    {
                        path: 'permissions',
                        element: <PermissionsList />,
                    },
                    {
                        path: 'permissions/create',
                        element: <PermissionPage />,
                    },
                    {
                        path: 'permissions/:permissionId',
                        element: <PermissionPage />,
                    },
                ],
            },
            {
                path: 'tech-page',
                element: <TechnicPage />,
            },
            {
                path: 'personal-account',
                element: <PersonalAccount />,
            },
            {
                path: 'session-expired',
                element: <SessionExpiredPage />,
            },
        ],
    },
]);
