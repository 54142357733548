import React from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmModal } from '@libs/components';

import { usePermissionPageFacade } from './usePermissionPageFacade';
import styles from './styles.module.scss';

export const PermissionPage = () => {
    const {
        control,
        isDirty,
        isValid,
        handleSubmit,
        onSubmit,
        isEditing,
        products,
        openModal,
        isOpen,
        closeModal,
        eventHandler,
    } = usePermissionPageFacade();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>
                        {isEditing ? 'Редактирование разрешения' : 'Создание разрешения'}
                    </span>
                    <div className={classNames('flex', 'align-items-center', styles.filterGroup)}>
                        {isEditing && (
                            <Button severity="danger" onClick={openModal}>
                                Удалить
                            </Button>
                        )}
                    </div>
                </div>

                <Controller
                    control={control}
                    name="productGuid"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="productGuid">Продукт</label>
                            <Dropdown
                                {...field}
                                options={products}
                                className={classNames(styles.input, fieldState.error && styles.redBorder)}
                                placeholder="Выберите продукт"
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>Пожалуйста, заполните поле</span>
                            )}
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="code"
                    rules={{ required: true, maxLength: 50 }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="code">Мнемокод разрешения</label>
                            <InputText
                                {...field}
                                className={classNames(styles.input, fieldState.error && styles.redBorder)}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>
                                    Пожалуйста, заполните поле (максимум 50 символов)
                                </span>
                            )}
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="title"
                    rules={{ required: true, maxLength: 100 }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="title">Название разрешения</label>
                            <InputText
                                {...field}
                                className={classNames(styles.input, fieldState.error && styles.redBorder)}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>
                                    Пожалуйста, заполните поле (максимум 100 символов)
                                </span>
                            )}
                        </div>
                    )}
                />

                <div className={classNames('flex', 'align-item-center', styles.buttonsContainer)}>
                    <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                        Отмена
                    </Button>
                    <Button
                        severity="secondary"
                        outlined
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isDirty || !isValid}
                    >
                        {isEditing ? 'Сохранить' : 'Создать'}
                    </Button>
                </div>
            </div>

            <ConfirmModal
                header={'Подтвердить действие'}
                confirmButtonText={'Подтвердить'}
                onConfirm={eventHandler}
                onClose={closeModal}
                isVisible={isOpen}
                children={<p>Вы уверены, что хотите удалить это разрешение?</p>}
            />
        </div>
    );
};
