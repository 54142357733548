import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { HasAccess } from '@libs/components/access';
import { usePermissions } from '@libs/hooks';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { InputNumber } from 'primereact/inputnumber';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import { useDiscountPageFacade } from './useSubscriptionPageFacade';

export const DiscountPage = ({ isEdit }: { isEdit: boolean }) => {
    const { tariffId, tariffPlanId } = useParams();

    const {
        control,
        setValue,
        watch,
        trigger,
        isDirty,
        isValid,
        onSubmit,
        handleSubmit,
        errorMessage,
        setErrorMessage,
    } = useDiscountPageFacade({
        isEdit,
    });

    const { rootAdminPermissions, isAdmin } = usePermissions();

    return (
        <div className={classNames(styles.form, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', styles.formFields)}>
                <span className={classNames(styles.title)}>
                    <BackArrow link={`discounts/${tariffId}/${tariffPlanId}`} />
                    {isEdit ? 'Редактирование скидки' : 'Создание скидки'}
                </span>
                {isEdit && (
                    <Controller
                        control={control}
                        name="id"
                        rules={{ required: true }}
                        render={({ field, fieldState }) => (
                            <div className={classNames('flex flex-column gap-2')}>
                                <label htmlFor="id">Id</label>
                                <InputNumber
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value}
                                    disabled
                                />
                            </div>
                        )}
                    />
                )}
                <Controller
                    control={control}
                    name="percent"
                    rules={{
                        required: 'Пожалуйста, заполните поле',
                        min: { value: 0, message: 'Минимальное значение поля - 0' },
                        max: { value: 100, message: 'Максимальное значение поля - 100' },
                    }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="percent">Процент</label>
                            <InputNumber
                                {...field}
                                className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                value={field.value}
                                disabled={!isAdmin}
                                onChange={(event) => field.onChange(event.value)}
                                placeholder={'Введите процент'}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>{fieldState.error.message}</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="dateStart"
                    rules={{
                        required: true,
                    }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="dateStart">Дата начала</label>
                            <Calendar
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    setValue('dateStart', e.value, { shouldValidate: true });
                                    trigger('dateStart');
                                    trigger('dateEnd');

                                    setErrorMessage('');
                                }}
                                dateFormat="dd.mm.yy"
                                locale="ru"
                                value={field.value}
                                placeholder="Выберите дату"
                                className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>{fieldState.error.message}</span>
                            )}
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="dateEnd"
                    rules={{
                        required: true,
                        validate: (val) => isAfter(val, watch('dateStart')) ||
                            isSameDay(val, watch('dateStart')) ||
                            'Дата окончания не может быть раньше даты начала',
                    }}
                    render={({ field, fieldState }) => (
                        <div className="flex flex-column gap-2">
                            <label htmlFor="dateEnd">Дата окончания</label>
                            <Calendar
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    setValue('dateEnd', e.value, { shouldValidate: true });
                                    trigger('dateEnd');
                                    trigger('dateStart');
                                    setErrorMessage('');
                                }}
                                value={field.value}
                                dateFormat="dd.mm.yy"
                                locale="ru"
                                placeholder="Выберите дату"
                                className={classNames(styles.calendar, fieldState.error && styles.redBorder)}
                                disabled={!isAdmin}
                            />
                            {fieldState.error && (
                                <span className={classNames(styles.errorHint)}>{fieldState.error.message}</span>
                            )}
                        </div>
                    )}
                />
                <HasAccess permissions={rootAdminPermissions}>
                    <div className={classNames('flex', 'align-item-center', styles.buttonsContainer)}>
                        <Button severity="secondary" outlined onClick={() => window.location.reload()}>
                            Отмена
                        </Button>
                        <Button
                            severity="secondary"
                            outlined
                            onClick={handleSubmit(onSubmit)}
                            disabled={!isDirty || !isValid || !!errorMessage}
                        >
                            {isEdit ? 'Сохранить' : 'Создать'}
                        </Button>
                    </div>
                </HasAccess>
                {errorMessage && <span className={classNames(styles.errorMessage)}>{errorMessage}</span>}
            </div>
        </div>
    );
};
