import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import {
    DiscountResType,
    type CreateDiscountType,
    type DatashopTariff,
    type Discount,
    type PaginationResponseType,
    type Product,
} from '@libs/types';
import { type JSONContent } from '@tiptap/react';

import {
    type GetTariffsRequestType,
    type GetProductsRequestType,
    type GetProductRequestType,
    type GetTariffRequestType,
    type GetPermissionsType,
    type PersmissionsRequestType,
    type UpdatePermissionsType,
    type CategoryResponse,
    type UpdateReferenceArgs,
    type Article,
    type GetArticlesRequest,
    type GetReferencesRequest,
    type Reference,
    type Content,
} from './types';

export const PRODUCTS_URL = '/moex-datashop-productservice/api/admin/product/v1';

export const DISCOUNTS_URL = '/moex-datashop-productservice/api/admin/v1/discounts';

export const UPDATE_PRODUCTS_URL = '/moex-datashop-productservice/api/admin/v1/product';

export const TARIFFS_URL = '/moex-datashop-productservice/api/admin/tariff/v1';

export const UPDATE_TARIFFS_URL = '/moex-datashop-productservice/api/admin/v1/tariff';

export const SUBSCRIPTIONS_URL = '/moex-datashop-datashopservice/api/admin/v1/subscriptions';

export const PERMISSIONS_URL = '/moex-datashop-datashopservice/api/admin/v1/permissions';
const ARTICLES_URL = '/moex-datashop-reference-service/api/admin/v1/articles';
const CATEGORIES_URL = '/moex-datashop-reference-service/api/admin/v1/dictionaries';
const REFERENCES_URL = '/moex-datashop-reference-service/api/admin/v1/references';

export const getProducts = async (requestData: GetProductsRequestType): Promise<AxiosResponse<GetProductRequestType>> =>
    await httpClient.post(`${PRODUCTS_URL}/search`, requestData);

export const exportProducts = async (requestData: GetProductsRequestType): Promise<AxiosResponse<Blob>> =>
    await httpClient.post(`${PRODUCTS_URL}/export`, { responseType: 'blob', ...requestData });

export const updateProductsCatalog = async (): Promise<AxiosResponse> =>
    await httpClient.post(`${UPDATE_PRODUCTS_URL}/update-catalog`);

export const getProductById = async (product_id: string): Promise<AxiosResponse<Product>> =>
    await httpClient.get(`${PRODUCTS_URL}/${product_id}`);

export const updateProduct = async (productId: string, formData: FormData): Promise<AxiosResponse<Product>> =>
    await httpClient.post(`${PRODUCTS_URL}/${productId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });

export const getTariffs = async (requestData: GetTariffsRequestType): Promise<AxiosResponse<GetTariffRequestType>> =>
    await httpClient.post(`${TARIFFS_URL}/search`, requestData);

export const exportTariffs = async (requestData: GetProductsRequestType): Promise<AxiosResponse<Blob>> =>
    await httpClient.post(`${TARIFFS_URL}/export`, { responseType: 'blob', ...requestData });

export const updateTariffsCatalog = async (): Promise<AxiosResponse> =>
    await httpClient.post(`${UPDATE_TARIFFS_URL}/update-catalog`);

export const getTariffById = async (tariff_id: string): Promise<AxiosResponse<DatashopTariff>> =>
    await httpClient.get(`${TARIFFS_URL}/${tariff_id}`);

export const getArticles = async (
    requestData: GetArticlesRequest,
): Promise<
    AxiosResponse<{
        createdDate: string;
        status: null;
        validation: null;
        data: {
            totalRows: number;
            totalPages: number;
            size: number;
            rows: [];
            pageNumber: number;
        };
    }>
> => {
    return await httpClient.post(`${ARTICLES_URL}/search`, requestData);
};

export const getCategories = async (): Promise<AxiosResponse<CategoryResponse>> => {
    return await httpClient.get(CATEGORIES_URL);
};

export const createArticle = async (
    requestData: FormData,
): Promise<
    AxiosResponse<{
        createdDate: string;
        status: null;
        validation: null;
        data: Article;
    }>
> => {
    return await httpClient.post(`${ARTICLES_URL}`, requestData, {
        headers: { 'Content-type': 'multipart/form-data' },
    });
};

export const getArticle = async (id: string) => {
    return await httpClient.get<Article>(`${ARTICLES_URL}/${id}`);
};

export const updateArticle = async (id: string, data: FormData) => {
    return await httpClient.post(`${ARTICLES_URL}/${id}`, data, {
        headers: { 'Content-type': 'multipart/form-data' },
    });
};

export const getReferences = async (
    data: GetReferencesRequest,
): Promise<AxiosResponse<{ data: PaginationResponseType<Reference> }>> => {
    return await httpClient.post(`${REFERENCES_URL}/search`, data);
};

export const createReference = async (data: FormData): Promise<AxiosResponse> => {
    return await httpClient.post(REFERENCES_URL, data);
};

export const getReference = async (id: string) => {
    return await httpClient.get(`${REFERENCES_URL}/${id}`);
};

export const updateReference = async (id: string, data: UpdateReferenceArgs) => {
    return await httpClient.post(`${REFERENCES_URL}/${id}`, data);
};

export const getArticleContent = async (id: string): Promise<AxiosResponse<Content>> => {
    return await httpClient.get(`${ARTICLES_URL}/${id}/content`);
};

export const updateArticleContent = async (id: string, data: JSONContent): Promise<AxiosResponse<Content>> => {
    return await httpClient.post(`${ARTICLES_URL}/${id}/content`, data, {
        headers: { 'Content-type': 'text/plain' },
    });
};

export const getPermissions = async (
    requestData: GetPermissionsType,
): Promise<AxiosResponse<PersmissionsRequestType>> => {
    return await httpClient.post(`${PERMISSIONS_URL}/search`, requestData);
};

export const updateTariff = async (
    tariffId: string,
    requestData: DatashopTariff,
): Promise<AxiosResponse<DatashopTariff>> => await httpClient.post(`${TARIFFS_URL}/${tariffId}`, requestData);

export const updatePermissions = async (
    perId: string,
    requestData: UpdatePermissionsType,
): Promise<AxiosResponse<PersmissionsRequestType>> => await httpClient.post(`${PERMISSIONS_URL}/${perId}`, requestData);

export const getDiscounts = async (requestData: { tariffPlanId: number }): Promise<AxiosResponse<Discount[]>> =>
    await httpClient.post(`${DISCOUNTS_URL}/search`, requestData);

export const getDiscountById = async (id: string): Promise<AxiosResponse<Discount>> =>
    await httpClient.get(`${DISCOUNTS_URL}/${id}`);

export const updateDiscountById = async (requestData: Discount): Promise<AxiosResponse<Discount>> =>
    await httpClient.post(`${DISCOUNTS_URL}/${requestData.id}`, requestData);

export const createDiscount = async (requestData: CreateDiscountType): Promise<AxiosResponse<DiscountResType>> =>
    await httpClient.post(DISCOUNTS_URL, requestData);
