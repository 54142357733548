import classNames from 'classnames';
import { TariffStatus } from '@modules/AdminPage/services/types';
import { type Control, Controller, useFieldArray } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { type DatashopTariff } from '@libs/types';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { usePermissions } from '@libs/hooks';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import { TariffOptionsForm } from './TariffOptionsForm';

type TariffPlanProps = {
    index: number;
    control: Control<DatashopTariff, any>;
};

export const TariffPlansForm = (props: TariffPlanProps) => {
    const { tariffId } = useParams();
    const { control, index } = props;
    const { isAdmin } = usePermissions();
    const navigate = useNavigate();

    const { fields } = useFieldArray({
        control,
        name: `billingTables.${index}.tariffPlans`,
    });

    const handleDiscountClick = (tariffPlanId: number) => {
        navigate(`/discounts/${tariffId}/${tariffPlanId}`);
    };

    return (
        <div className={styles.form}>
            {fields.map((el, planIndex) => (
                <div key={el.tariffPlanId} className={styles.form}>
                    <div className={classNames('flex', 'align-items-center')}>
                        <Controller
                            control={control}
                            disabled
                            name={`billingTables.${index}.tariffPlans.${planIndex}.tariffPlanId`}
                            render={({ field, fieldState }) => (
                                <div className={classNames('flex flex-column gap-2', styles.fieldId)}>
                                    <label htmlFor="id">ID</label>
                                    <InputText {...field} value={field.value.toString()} onChange={field.onChange} />
                                </div>
                            )}
                        />
                        <Button
                            severity="secondary"
                            outlined
                            className={classNames(styles.changeButton)}
                            onClick={() => handleDiscountClick(el.tariffPlanId)}
                        >
                            Скидки
                        </Button>
                    </div>
                    <Controller
                        control={control}
                        name={`billingTables.${index}.tariffPlans.${planIndex}.guid`}
                        disabled
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="guid">ID ТПК</label>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name={`billingTables.${index}.tariffPlans.${planIndex}.name`}
                        disabled
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="name">Название</label>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name={`billingTables.${index}.tariffPlans.${planIndex}.cost`}
                        disabled
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="cost">Стоимость</label>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    value={field.value as unknown as string}
                                    onChange={field.onChange}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name={`billingTables.${index}.tariffPlans.${planIndex}.isEnabled`}
                        defaultValue={false}
                        render={({ field }) => (
                            <div className={classNames('flex', 'gap-2', 'align-items-center')}>
                                <label htmlFor="isEnabled">Доступность</label>
                                <Checkbox
                                    checked={field.value as boolean}
                                    onChange={field.onChange}
                                    disabled={!isAdmin}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name={`billingTables.${index}.tariffPlans.${planIndex}.dopInfoFirstLineDescription`}
                        defaultValue={el.dopInfoFirstLineDescription ?? ''}
                        render={({ field, fieldState }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="dopInfoFirstLineDescription">Заголовок описания</label>
                                <InputText
                                    {...field}
                                    className={classNames(styles.dropdownInput, fieldState.error && styles.redBorder)}
                                    onChange={(e) => field.onChange(e.target.value)}
                                    value={field.value ?? ''}
                                    disabled={!isAdmin}
                                />
                            </div>
                        )}
                    />
                    <label htmlFor="dopInfoOptions">Преимущества</label>
                    <TariffOptionsForm billingTablesIndex={index} tariffPlansIndex={planIndex} control={control} />
                    <Divider className={classNames(styles.divider)} />
                </div>
            ))}
        </div>
    );
};
