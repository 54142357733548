import classNames from 'classnames';
import { DataTable, type DataTableRowClickEvent } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { type Discount } from '@libs/types';
import { getDiscounts } from '@modules/AdminPage/services';
import { usePermissions } from '@libs/hooks';
import { HasAccess } from '@libs/components/access';
import { Button } from 'primereact/button';
import { BackArrow } from '@libs/components/pageBlocks/Navigation/BackArrow';

import styles from './styles.module.scss';

const tableColumns = [
    { field: 'id', header: 'ID' },
    { field: 'percent', header: 'Процент' },
    { field: 'dateStart', header: 'Дата начала' },
    { field: 'dateEnd', header: 'Дата окончания' },
];

const bodyTemplate = (data: Discount, options: ColumnBodyOptions) => {
    switch (options.field) {
        case 'dateStart':
            return data.dateStart && format(new Date(data.dateStart), 'dd.MM.yyyy');
        case 'dateEnd':
            return data.dateEnd && format(new Date(data.dateEnd), 'dd.MM.yyyy');
        default:
            return data[options.field as keyof Discount];
    }
};

export const DiscountsList = () => {
    const navigate = useNavigate();
    const { tariffId, tariffPlanId } = useParams();

    const [discounts, setDiscounts] = useState<Discount[] | undefined>();

    const handleTableRowClick = (e: DataTableRowClickEvent) => {
        navigate(`/discounts/discount/${tariffId}/${tariffPlanId}/${e.data.id}`);
    };

    const handleСreateClick = () => {
        if (tariffPlanId) {
            navigate(`/discounts/${tariffId}/${tariffPlanId}/create`);
        }
    };

    useEffect(() => {
        const fetchDiscounts = async () => {
            try {
                if (tariffPlanId) {
                    const data = await getDiscounts({
                        tariffPlanId: Number(tariffPlanId),
                    });
                    setDiscounts(data.data);
                }
            } catch (error) {
                console.log('error: ', error);
            }
        };
        fetchDiscounts();
    }, []);

    const { rootAdminPermissions } = usePermissions();

    return (
        <div className={classNames('flex', 'flex-1')}>
            <div className={classNames(styles.wrapper)}>
                <div className={classNames(styles.filters, 'flex', 'align-items-center')}>
                    <span className={classNames(styles.title)}>
                        <BackArrow link={`/subscriptions/${tariffId}`} />
                        Скидки
                    </span>
                    <div className={classNames('flex', 'align-items-center', styles.btnGroup)}>
                        <HasAccess permissions={rootAdminPermissions}>
                            <Button severity="secondary" outlined onClick={handleСreateClick}>
                                Создать
                            </Button>
                        </HasAccess>
                    </div>
                </div>
                <DataTable
                    value={discounts}
                    className={classNames(styles.table)}
                    onRowClick={handleTableRowClick}
                    emptyMessage={'Скидки отсутствуют'}
                >
                    {tableColumns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            alignHeader="center"
                            className={classNames('text-center')}
                            body={bodyTemplate}
                        />
                    ))}
                </DataTable>
            </div>
        </div>
    );
};
